<template>
  <el-menu
    :default-active="activeTabName"
    class="el-menu-demo"
    mode="horizontal"
  >
    <el-menu-item index="MyMaterials" class="tabBody">
      <b>
        <router-link :to="{ name: 'MyMaterials' }" class="linkInTab">
          {{ $t("materials.myMaterials") }}
        </router-link>
      </b>
    </el-menu-item>
    <el-menu-item index="Materials" class="tabBody">
      <b>
        <router-link :to="{ name: 'Materials' }" class="linkInTab">
          {{ $t("materials.allMaterials") }}
        </router-link>
      </b>
    </el-menu-item>
  </el-menu>
</template>

<script>
export default {
  computed: {
    activeTabName() {
      return this.$route.name;
    },
    isLogin() {
      return this.$store.getters["user/token"] !== "";
    },
    isFreeMember() {
      return this.$store.getters["user/isFreeMember"];
    }
  }
};
</script>
<style lang="scss" scoped>
.linkInTab {
  text-decoration: none;
  color: inherit !important;
}
.linkInTab::after {
  content: "";
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.tabBody {
  position: relative;
}
</style>
