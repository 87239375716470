<template>
  <div>
    <PageTitle class="headerTitle">
      {{ $t("materials.allMaterials") }}
    </PageTitle>
    <MaterialsTypes />
    <el-table :data="materials.data" :empty-text="$t('courses.empty-text')">
      <el-table-column width="40px">
        <template slot-scope="scope">
          <MaterialIcon :extension="scope.row.file_extension" />
        </template>
      </el-table-column>
      <el-table-column :label="$t('materials.column.name')" min-width="165px">
        <template slot-scope="scope">
          <div>
            <div class="title">
              {{ `${scope.row.name}.${scope.row.file_extension}` }}
            </div>
            <div class="description">
              {{ scope.row.description }}
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('materials.column.preview')" align="center" width="80px">
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            :content="$t('materials.preview')"
            placement="top"
            popper-class="tooltipColor"
          >
            <a target="_blank" :href="scope.row.preview_url">
              <i class="fas fa-eye" />
            </a>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column :label="$t('materials.column.size')" align="center" width="80px">
        <template slot-scope="scope">
          <div>{{ scope.row.size }}</div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('materials.column.content')" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.content }}</div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('materials.column.price')" width="140px" align="center">
        <template slot-scope="scope">
          <div class="d-flex flex-column justify-content-center">
            <div v-if="(scope.row.price_free + scope.row.price_pro) === 0">
              {{ $t("materials.free") }}
            </div>
            <div v-else>
              <div v-if="scope.row.price_free > 0">
                {{ getPrice(scope.row.price_free) }}
              </div>
              <div v-if="scope.row.price_pro > 0">
                {{ getPrice(scope.row.price_pro) }}
              </div>
              <router-link v-else :to="{ name: 'ToeflPlans' }">
                {{ $t("materials.freeForPro") }}
              </router-link>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('materials.column.buy')" width="80px" align="center">
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="mini"
            @click="() => buyMaterial(scope.row.id)"
          >
            {{ $t("materials.column.buy") }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="text-center" style="margin:15px 0">
      <el-pagination
        background
        @current-change="page => fetchMaterials({ page })"
        :page-size="materials.per_page"
        :current-page="materials.current_page"
        :total="materials.total"
        layout="prev, pager, next"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import MaterialsTypes from "@/views/toefl/materials/MaterialsTypes";
import MaterialIcon from "@/views/toefl/materials/MaterialIcon";
import materialsApi from "@/apis/materials";

export default {
  metaInfo() {
    return {
      title: `${this.$t("header.materials")} - ` + this.CompanyName
    };
  },
  components: {
    PageTitle,
    MaterialsTypes,
    MaterialIcon
  },
  data() {
    return {
      materials: {}
    };
  },
  created() {
    let fetchQuery = {};
    if (this.$route.query.categoryId) {
      fetchQuery.material_category_id = this.$route.query.categoryId;
    }
    this.fetchMaterials(fetchQuery);
  },
  methods: {
    async fetchMaterials(data = {}) {
      const { materials } = await materialsApi.getMaterials(data);
      this.materials = materials;
    },
    async buyMaterial(materialId) {
      await materialsApi.buyMaterial(materialId);
      this.$message.success(this.$t("materials.buyMaterialSuccessfully"));
      this.fetchMaterials({
        page: this.materials.current_page
      });
    },
    getPrice(price) {
      return this.$t("materials.T-Coins", { coin: price });
    }
  }
};
</script>

<style lang="scss" scoped src="@/views/toefl/materials/index.scss"></style>
