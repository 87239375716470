<template>
  <div class="container">
    <MaterialsHeader />
    <Materials v-if="$route.name === 'Materials'" />
    <MyMaterials v-else />
  </div>
</template>

<script>
import MaterialsHeader from "@/views/toefl/materials/MaterialsHeader";
import Materials from "@/views/toefl/materials/Materials";
import MyMaterials from "@/views/toefl/materials/MyMaterials";

export default {
  components: {
    MaterialsHeader,
    Materials,
    MyMaterials
  }
};
</script>

<style lang="scss" scoped>
.container {
  padding-top: 40px;
}

.title {
  font-weight: bold;
}

.description {
  color: #a4a4a4;
}

.row {
  margin-top: 20px;
}

.btn-list {
  display: flex;
  justify-content: space-between;
}

.btn-item {
  width: 100%;
  margin-right: 40px;
}

.btn {
  width: 100%;
  height: 35px;
  line-height: 35px;
  cursor: pointer;
  background-color: #f5f5f5;
  color: var(--themeColor);
  text-align: center;
  border-radius: 5px;
  padding: 0 5px;
  margin: 10px 0;
}

.btn:hover,
.active {
  color: white;
  background: var(--themeColor);
  box-shadow: 1px 3px 6px #aaa;
}

.documentIcon {
  font-size: 26px;
}
</style>
