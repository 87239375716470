<template>
  <div>
    <hr />
    <div class="row">
      <div class="d-none d-md-block col-md-2 ivy-type-title">
        {{ $t("flashCard.i18n4") }}
      </div>
      <div class="col-md-10 btn-list btn-list-md">
        <div
          :key="category.id"
          v-for="category in categories"
          class="btn-item"
          @click="() => (currentCategoryId = category.id)"
        >
          <label :class="currentCategoryId === category.id ? 'btn active' : 'btn'">
            {{ category.name[lang] }}
          </label>
          <router-link
            class="stretch"
            :to="{ name: $route.name, query: { categoryId: category.id } }"
          />
        </div>
      </div>
      <div
        :key="category.id"
        v-for="category in categories"
        class="btn-item btn-xs"
        @click="() => (currentCategoryId = category.id)"
      >
        <label :class="currentCategoryId === category.id ? 'btn active' : 'btn'">
          {{ category.name[lang] }}
        </label>
        <router-link
          class="stretch"
          :to="{ name: $route.name, query: { categoryId: category.id } }"
        />
      </div>
    </div>
    <hr />
  </div>
</template>

<script>
import materialsApi from "@/apis/materials";

export default {
  computed: {
    lang() {
      return this.$store.getters["user/langShortValue"];
    }
  },
  data() {
    return {
      currentCategoryId: "",
      categories: []
    };
  },
  async created() {
    const { material_categories } = await materialsApi.getMaterialCategories();
    this.categories = material_categories;

    if (this.$route.query.categoryId) {
      this.currentCategoryId = Number(this.$route.query.categoryId);
    }
  }
};
</script>

<style lang="scss" scoped>
.ivy-type-title {
  font-size: 15px;
  font-weight: bold;
  height: 35px;
  line-height: 35px;
  margin: 10px 0;
  text-align: right;
}

.btn-list {
  display: flex;
  justify-content: space-between;
}

.btn-xs {
  display: none;
}

@media screen and (max-width: 992px) {
  .btn-list-md {
    display: none;
  }

  .btn-xs {
    display: block;
  }
}

.btn-item {
  width: 100%;
  margin: 0px 20px;
  position: relative;
}

.stretch {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.btn {
  width: 100%;
  height: 35px;
  line-height: 35px;
  cursor: pointer;
  background-color: #f5f5f5;
  color: var(--themeColor);
  text-align: center;
  border-radius: 5px;
  padding: 0 5px;
  margin: 10px 0;
}

.btn:hover,
.active {
  color: white;
  background: var(--themeColor);
  box-shadow: 1px 3px 6px #aaa;
}
</style>