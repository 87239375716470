<template>
  <div>
    <PageTitle class="headerTitle">
      {{ $t("materials.myMaterials") }}
    </PageTitle>
    <MaterialsTypes />
    <template v-if="!loadingMaterials">
      <template v-if="(materials.data || []).length > 0">
        <el-table :data="materials.data" :empty-text="$t('courses.empty-text')">
          <el-table-column width="40px">
            <template slot-scope="scope">
              <MaterialIcon :extension="scope.row.file_extension" />
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('materials.column.name')"
            min-width="165px"
          >
            <template slot-scope="scope">
              <div>
                <div class="title">
                  {{ `${scope.row.name}.${scope.row.file_extension}` }}
                </div>
                <div class="description">
                  {{ scope.row.description }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('materials.column.size')"
            align="center"
            width="80px"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.size }}</div>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('materials.column.content')"
            align="center"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.content }}</div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('materials.column.price')" width="140px" align="center">
            <template slot-scope="scope">
              <div class="d-flex flex-column justify-content-center">
                <div v-if="(scope.row.price_free + scope.row.price_pro) === 0">
                  {{ $t("materials.free") }}
                </div>
                <div v-else>
                  <div v-if="scope.row.price_free > 0">
                    {{ getPrice(scope.row.price_free) }}
                  </div>
                  <div v-if="scope.row.price_pro > 0">
                    {{ getPrice(scope.row.price_pro) }}
                  </div>
                  <router-link v-else :to="{ name: 'ToeflPlans' }">
                    {{ $t("materials.freeForPro") }}
                  </router-link>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('materials.column.download')" width="120px" align="center">
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="mini"
                @click="() => downloadMaterial(scope.row.id)"
              >
                {{ $t("materials.column.download") }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="text-center" style="margin:15px 0">
          <el-pagination
            background
            @current-change="page => fetchMaterials({ page })"
            :page-size="materials.per_page"
            :current-page="materials.current_page"
            :total="materials.total"
            layout="prev, pager, next"
          >
          </el-pagination>
        </div>
      </template>
      <template v-else>
        <div class="d-flex flex-column justify-content-center align-items-center emptyMaterials">
          <div>
            {{ $t("materials.doNotHaveAnything") }}
          </div>
          <router-link :to="{ name: 'Materials' }">
            {{ $t("materials.seeWhatWeHave") }}
          </router-link>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import MaterialsTypes from "@/views/toefl/materials/MaterialsTypes";
import MaterialIcon from "@/views/toefl/materials/MaterialIcon";
import materialsApi from "@/apis/materials";

export default {
  metaInfo() {
    return {
      title: "My Materials - " + this.CompanyName
    };
  },
  components: {
    PageTitle,
    MaterialsTypes,
    MaterialIcon
  },
  data() {
    return {
      loadingMaterials: true,
      currentType: "files",
      materials: {}
    };
  },
  async created() {
    let fetchQuery = {};
    if (this.$route.query.categoryId) {
      fetchQuery.material_category_id = this.$route.query.categoryId;
    }
    await this.fetchMaterials(fetchQuery);
    this.loadingMaterials = false;
  },
  methods: {
    async fetchMaterials(data = {}) {
      const { materials } = await materialsApi.getMyMaterials(data);
      this.materials = materials;
    },
    getMyMaterial(materialId) {
      return this.materials.data.find(({ id }) => id === materialId);
    },
    async downloadMaterial(materialId) {
      const file = await materialsApi.downloadMaterial(materialId);
      const material = this.getMyMaterial(materialId);
      const element = document.createElement("a");
      const url = window.URL.createObjectURL(new Blob([file]), {
        type: `application/${material.file_extension}`
      });
      element.href = url;
      element.setAttribute(
        "download",
        `${material.name}.${material.file_extension}`
      );
      element.style.display = "none";
      document.body.appendChild(element);

      element.click();
      document.body.removeChild(element);
    },
    getPrice(price) {
      return this.$t("materials.T-Coins", { coin: price });
    }
  }
};
</script>

<style lang="scss" scoped src="@/views/toefl/materials/index.scss"></style>
<style lang="scss" scoped>
.emptyMaterials {
  margin-top: 140px;
  font-size: 20px;

  div {
    margin-bottom: 32px;
  }

  a {
    font-weight: bold;
  }
}
</style>
