import ApiService from "@/common/api.service";

export default {
  getMaterials(data) {
    return ApiService.query("materials", data);
  },
  buyMaterial(materialId) {
    return ApiService.post(`material/${materialId}/buy`);
  },
  getMyMaterials(data) {
    return ApiService.query("materials/bought", data);
  },
  getMaterialCategories() {
    return ApiService.query("material-categories");
  },
  downloadMaterial(materialId) {
    return ApiService.get(
      `material/download/${materialId}`,
      "",
      {},
      { responseType: "blob" }
    );
  }
};
