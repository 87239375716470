<template>
  <div v-if="extension === 'pdf'">
    <span class="documentIcon pdf">
      <i class="fas fa-file-pdf" />
    </span>
  </div>
  <div v-else-if="['doc', 'docx'].includes(extension)">
    <span class="documentIcon doc">
      <i class="fa-solid fa-file-lines" />
    </span>
  </div>
  <div v-else-if="['rar', 'zip'].includes(extension)">
    <span class="documentIcon archive">
      <i class="fas fa-file-archive" />
    </span>
  </div>
  <div v-else-if="['jpg', 'jepg', 'png'].includes(extension)">
    <span class="documentIcon image">
      <i class="far fa-image" />
    </span>
  </div>
</template>

<script>
export default {
  props: ["extension"]
};
</script>

<style lang="scss" scoped src="@/views/toefl/materials/index.scss"></style>
<style lang="scss" scoped>
.pdf {
  color: #EA4335;
}
.doc {
  color: #4284F3;
}
.archive {
  
}
.image {
  color: #fd7541;
}
</style>
